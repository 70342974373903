import * as React from "react"
import { Container } from "react-bootstrap"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => (
  <Layout>
    <Seo title="Testimonials" />
    <div className="blueBG">
      <Container className="singleCol">
        <h1>Testimonials</h1>
       
      </Container>
    </div>
    <Container className="singleCol two-rem-padding">
      <blockquote>
        <p>
          Julia has helped us from the beginning with our daughter. We initially came to Julia with the suspicion that our daughter, age 8 and in 2nd grade at the time, had dyslexia. She did the Slingerland screener along with other assessments and recommended that we get further tests. She helped us through the process to find a neuropsych. We started tutoring with Julia immediately while we went through the process of assessments and eventually a diagnosis of dyslexia.
        </p>
        <p>
          Julia is extremely knowledgeable and an expert in her field. Before finding her, I had asked around and researched many tutors. It was critical to find one that not only knows about dyslexia and reading disabilities, but is also skilled in teaching methods that are effective.
        </p>
        <p>
          Since working with Julia, my daughter has made huge progress in reading and writing. Her professionalism, knowledge and experience are beyond impressive. I know that my daughter is getting what she needs every session. We feel fortunate to be able to work with her.
        </p>
        <cite>~ Angie P., San Anselmo, CA</cite>

        
      </blockquote>
      <blockquote>
        <p>
          Julia has worked with our son over the past 2 years. He has struggled to read and write and was diagnosed with Dyslexia in 2nd grade. The school interventions were not working so we decided to look for a specialized tutor. 
        </p>
        <p>
          Since working with Julia, his reading and writing has dramatically improved. During distance learning Julia continued to engage him and he made more progress with tutoring than he did with traditional online school. 
        </p>
        <p>
          Additionally, Julia’s advocacy and goal writing skills have assisted us with IEP meetings. She has helped us reviewing the many pages of assessments and goals written by the school. Her attention to details helped us catch things a parent wouldn’t notice. Julia knows his needs and is able to help me rewrite his goals to better fit his specific needs. 
        </p>
        <cite>-	Jennifer and Robert H., Kentfield, CA.</cite>
      </blockquote>
      <blockquote>
        <p>
          Our experience with Julia has been extraordinarily positive. Our daughter who is now 12 years old was diagnosed with dyslexia at the age of 7 and has had multiple specialized interventions and programs as well as tutors over the past 5 years. We have found Julia to be the most professional and knowledgeable of any we have known.
        </p>
        <p>
          Julia came highly recommended by her colleagues and is by far the most specialized in her field. She has a multitude of specialized skills which include but are not limited to reading IEPs, goal writing and determining levels of service.
        </p>
        <p>
          Once more, I have been present for our daughter’s tutoring sessions and I was immediately impressed by Julia’s ability to immediately identify our daughter’s specific areas of need. She was able to begin working on those areas by the second session and had both a warm and welcoming approach with our daughter but combined with the ‘no nonsense’ attitude of getting right to work at each meeting.
        </p>
        <p>
          I would recommend Julia to any friend or family member without hesitation. She has been extremely flexible with our ever-changing work/school and activity schedules. She offered in person with safety protocols during the pandemic as well as a Zoom option so that sessions would not be missed. I work in the medical field and see Julia as one of the most capable in a field of disability that often goes undetected and overlooked.
        </p>
        <cite>-	Nicole N., Corte Madera, CA</cite>

        
      </blockquote>

    </Container>
  </Layout>
)

export default IndexPage
